/* Table Styles */

.sort {
  cursor: pointer;
  right: 0;
  background-position: right 0.4375rem center;
  background-repeat: no-repeat;
  background-size: 0.75rem 0.75rem;
  color: var(--tableText);
}

.sort:hover {
  background-color: var(--background);
  transition: 0.5s;
}

.sort.asc {
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position-y: 1.4375rem;
}

.sort.desc {
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position-y: 1.4375rem;
}

.tabletab.first {
  border-top-left-radius: 0.1875rem;
}

.tabletab.last {
  border-top-right-radius: 0.1875rem;
}

.tabletab {
  cursor: pointer;
  font-size: 0.875rem;
  text-align: center;
  text-transform: uppercase;
  border-style: solid;
  border-width: .1rem;
  border-color: var(--primary);
  color: var(--primary);
  height: 1.875rem;
  line-height: 1.5rem;
}

.tabletab.selected {
  background-color: var(--primary);
  color: var(--white);
}

.TitleLine {
  width: 100%;
  height: 1.875rem;
  line-height: 0.9375rem;
  display: grid;
  grid-template-columns: auto 12.5rem 12.5rem;
  grid-template-rows: auto;
  border-bottom-style: solid;
  border-bottom-color: var(--primary);
  border-bottom-width: 0.25rem;
  font-size: 1.875rem;
  color: var(--tableText);
}

.TitleLine.full {
  grid-template-columns: auto;
}

main.table {
  background-color: var(--table);
  padding-left: 3.75rem;
}

.tHead.filterable {
  padding-left: 1.875rem;
  background-image: url(/assets/svgs/Filter.svg);
  background-size: 1.125rem;
  background-position: left 0rem top 0.4375rem;
  background-repeat: no-repeat;
}

.tHead {
  position: relative;
  float: left;
  color: var(--tableText);
  font-size: 0.875rem;
  font-weight: 600;
  font-family: 'Open Sans';
  background-color: var(--primary);
  border-left: 0.25rem solid var(--primary);
  height: 3.4375rem;
  text-overflow: ellipsis;
  transition: 0.5s;
  padding-left: 0.4375rem;
  white-space: nowrap;
}

.tHead span {
  overflow: hidden;
  padding-right: 2.5rem;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
}

.tHeadRow {
  box-sizing: border-box;
  border-bottom: solid 0.25rem var(--primary);
  background-color: var(--navigation);
  padding-left: 0.25rem;
  line-height: 3.4375rem;
  position: sticky !important;
  top: 0;
  z-index: 10;
  transition: all 0.15s linear;
  opacity: 0.97;
}

@supports ((-webkit-backdrop-filter: saturate(90%) blur(1.25rem)) or (backdrop-filter: saturate(90%) blur(1.25rem))) {
  .tHeadRow {
    background: none;
    opacity: 0.97;
    -webkit-backdrop-filter: saturate(90%) blur(1.25rem);
    backdrop-filter: saturate(90%) blur(1.25rem);
  }
}

.tIcon {
  height: 1.875rem;
  top: 0.625rem;
  bottom: 0.625rem;
  position: absolute;
  background-position: center center;
  width: 5.625rem;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
}

.tIcon.AWS {
  background-image: url(/assets/images/gateways-aws.png);
  width: 4.0625rem;
}

.tIcon.Microsoft {
  background-image: url(/assets/svgs/Azure.svg);
  background-position-x: 0.9375rem;
}

.tIcon.Google {
  background-image: url(/assets/svgs/cloud-logo.svg);
}

.tIcon.AliCloud {
  background-image: url(/assets/svgs/AlibabaCloudSmall.svg);
}

.inviteStatus.tYes {
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  margin-top: 0.4375rem;
  width: 2.1875rem;
  height: 1.5rem;
  display: inline-block;
}

.tRow:active {
  color: var(--primary);
  transition: 0.3s;
}

.inviteStatus.tYes:before {
  color: var(--green);
  content: '\e91e';
}

.inviteStatus:before {
  content: '\e904';
}

.inviteStatus {
  color: var(--red);
  font-family: 'icomoon' !important;
  speak: none;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  text-transform: none;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  margin-top: 0.4375rem;
  width: 2.1875rem;
  height: 1.5rem;
  display: inline-block;
}

.tRow {
  border-left: 0.25rem solid var(--navigation);
  background-color: var(--navigation);
  font-weight: normal;
  color: var(--tableText);
  line-height: 3.4375rem;
  position: relative;
  float: left;
  width: 100%;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.tRow:nth-child(even) {
  border-left: 0.25rem solid var(--background);
  background-color: var(--background);
  background: var(--background);
}

.tRow:hover {
  border-left: 0.25rem solid var(--primary);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.noData {
  width: 100%;
  height: 90%;
  top: 0rem;
  position: absolute;
  background-image: url(/assets/svgs/NoData.svg);
  background-position: top 6.375rem center;
  background-size: 11.25rem;
  color: var(--icon);
  opacity: 0.25;
  font-size: 1.5rem;
  text-align: center;
  padding-right: 1.875rem;
  line-height: 34.5625rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Open Sans';
}

.tCol .bubble {
  margin-left: 0rem;
}

.tCol #NetworkTimeline .bubble {
  margin-left: 1.25rem;
}

.tMenu.all {
  width: 10rem;
}

.tMenu.open {
  opacity: 1;
  pointer-events: all;
}

.tMenu {
  position: absolute;
  width: 9.375rem;
  background-color: var(--menu);
  border-radius: var(--inputBorderRadius);
  right: -1.25rem;
  top: 1.875rem;
  box-shadow: 0 0 0 0.0625rem var(--shadow), 0 0.5rem 1rem var(--shadow);
  z-index: 40;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  opacity: 0;
  height: 0rem;
  pointer-events: none;
  transition: 0.5s;
  overflow: hidden;
  color: var(--white);
}

.tMenu .tActionRow {
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.875rem;
  color: var(--white);
  width: 100%;
  position: relative;
  float: left;
  letter-spacing: 0rem;
  text-align: left;
  padding-left: 0.6875rem;
  padding-right: 0.625rem;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Open Sans';
}

.tMenu .tActionRow:hover {
  background-color: var(--primary);
  color: var(--white);
}

.tSelect {
  width: 1.5625rem;
  height: 1.5625rem;
  border: 0.125rem var(--offWhite) solid;
  border-radius: 0.9375rem;
  cursor: pointer;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
  position: absolute;
  top: 0.8125rem;
  left: 1.0625rem;
  background-color: var(--navigation);
}

.tSelect:hover {
  border: 0.125rem var(--primary) solid;
  cursor: pointer;
  background-color: var(--offWhite);
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.tSelect:active {
  transform: scale(0.9);
  transition: background-color 0.3s;
}

.tSelect.selected {
  background-color: var(--green);
  background-image: url(/assets/images/Icon_Check.png); //REPLACE
  background-size: 45%;
  background-position: center center;
  border: none;
}

.initials {
  width: 2rem;
  height: 2rem;
  border-radius: 1.125rem;
  background-color: linear-gradient(125deg, var(--primary), var(--secondary));
  color: var(--white);
  position: absolute;
  left: 0rem;
  top: 0.4375rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 2rem;
  margin-top: 0.1875rem;
  font-weight: 600;
  padding-left: 0.0625rem;
  margin-right: 0.625rem;
  background-color: #08dc5a; /*Fallback Color for Old Browsers*/
}

.tColSpace {
  padding-left: 3.4375rem;
}

.tCol.ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 2.4375rem;
}

.tCol {
  position: relative;
  float: left;
  font-size: 0.9375rem;
  font-weight: 400;
  font-family: 'Open Sans';
  text-overflow: ellipsis;
  height: 3.125rem;
  line-height: 3.125rem;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 0.4375rem;
}

.tRight {
  text-align: right;
  overflow: unset;
}

.clickCol {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dots {
  text-align: center;
  font-size: 2.625rem;
  line-height: 1.875rem;
  font-family: serif;
  letter-spacing: 0rem;
  cursor: pointer;
  height: 2.5rem;
  display: inline-block;
  width: 2.5rem;
  color: var(--stroke);
  position: absolute;
  right: 1.25rem;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.dots:hover {
  color: var(--primary);
  transition: 0.5s;
}

.dots:active {
  color: var(--primaryColorHover);
  transition: 0.3s;
}

.modal .table {
  position: relative;
  width: 100%;
  display: grid;
  color: var(--text);
  font-size: 0.875rem;
  line-height: 1.5rem;
  grid-template-columns: auto;
  margin-bottom: 0.3125rem;
}

.modal .table div {
  padding: 0.625rem;
}

.modal .table.three {
  grid-template-columns: 33% auto 33%;
}

.modal .table.two {
  grid-template-columns: 50% auto;
}

.modal .table .header {
  font-family: 'Open Sans';
  color: var(--tableText);
  font-weight: 800;
}

.borderBottom {
  border-bottom-color: var(--inputBorder);
  border-bottom-width: var(--inputBorderWidth);
  border-bottom-style: solid;
}

.borderRight {
  border-right-color: var(--inputBorder);
  border-right-width: var(--inputBorderWidth);
  border-right-style: solid;
}

.borderLeft {
  border-left-color: var(--inputBorder);
  border-left-width: var(--inputBorderWidth);
  border-left-style: solid;
}

.tableGrid {
  position: relative;
  width: 100%;
  display: grid;
  font-size: 0.75rem;
  grid-template-columns: auto;
  grid-column-gap: 0.3125rem;
}

.tableGrid.header {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--tableText);
  border-bottom-style: solid;
  border-bottom-color: var(--primary);
  border-bottom-width: var(--inputBorderWidth);
}

.tableGrid.four {
  grid-template-columns: auto 25% 25% 25%;
}

.tableGrid .staticCell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  color: var(--tableText);
}

.gridLabel {
  color: var(--primary);
  line-height: 3.125rem;
  font-weight: bold;
}

.t5 {
  width: 5%;
}

.t10 {
  width: 10%;
}

.t15 {
  width: 15%;
}

.t20 {
  width: 20%;
}

.t25 {
  width: 25%;
}

.t30 {
  width: 30%;
}

.t40 {
  width: 40%;
}

.t45 {
  width: 45%;
}

.t50 {
  width: 50%;
}

.t60 {
  width: 60%;
}

.t65 {
  width: 65%;
}

.t70 {
  width: 70%;
}

.t80 {
  width: 80%;
}

.t90 {
  width: 90%;
}

.filterButton {
  position: absolute;
  z-index: 20;
  width: 1rem;
  height: 1rem;
  top: 1.25rem;
  right: 2.0625rem;
  background-image: url(/assets/svgs/Filter.svg);
  background-size: contain;
  background-position: center center;
  cursor: pointer;
}

.filterButton .options {
  position: absolute;
  right: 0rem;
  z-index: 30;
  top: 1.25rem;
  box-shadow: var(--shadows);
  border-radius: 0.5rem;
  width: 12.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  height: 0rem;
  opacity: 0;
  overflow-x: hidden;
  overflow: hidden;
  background-color: var(--menu);
  transition: 0.75s;
}

.filterButton .options.open {
  width: 12.5rem;
  height: unset;
  opacity: 1;
  overflow: auto;
  transition: 0.75s;
}

.filterButton .options .option {
  width: 100%;
  height: 1.875rem;
  font-size: 0.875rem;
  line-height: 1.875rem;
  padding-left: 0.6875rem;
  padding-right: 0.625rem;
  color: var(--white);
  cursor: pointer;
  overflow: hidden;
  font-weight: 600;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.filterButton .options .option:hover {
  background-color: var(--primary);
}
