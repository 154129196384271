/* Table Styles */

.sort {
  cursor: pointer;
  right: 0;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  color: var(--tableText);
}

.sort:hover {
  background-color: var(--background);
  transition: 0.5s;
}

.sort.asc {
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-y: 23px;
}

.sort.desc {
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-y: 23px;
}

.tabletab.first {
  border-top-left-radius: 3px;
}

.tabletab.last {
  border-top-right-radius: 3px;
}

.tabletab {
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  border-style: solid;
  border-width: 1.5px;
  border-color: var(--primary);
  color: var(--primary);
  height: 30px;
  line-height: 24px;
}

.tabletab.selected {
  background-color: var(--primary);
  color: var(--white);
}

.TitleLine {
  width: 100%;
  height: 30px;
  line-height: 15px;
  display: grid;
  grid-template-columns: auto 200px 200px;
  grid-template-rows: auto;
  border-bottom-style: solid;
  border-bottom-color: var(--primary);
  border-bottom-width: 4px;
  font-size: 30px;
  color: var(--tableText);
}

.TitleLine.full {
  grid-template-columns: auto;
}

main.table {
  background-color: var(--table);
  padding-left: 60px;
}

.tHead.filterable {
  padding-left: 30px;
  background-image: url(/assets/svgs/Filter.svg);
  background-size: 18px;
  background-position: left 0px top 7px;
  background-repeat: no-repeat;
}

.tHead {
  position: relative;
  float: left;
  color: var(--tableText);
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans';
  background-color: var(--primary);
  border-left: 4px solid var(--primary);
  height: 55px;
  text-overflow: ellipsis;
  transition: 0.5s;
  padding-left: 7px;
  white-space: nowrap;
}

.tHead span {
  overflow: hidden;
  padding-right: 40px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
}

.tHeadRow {
  box-sizing: border-box;
  border-bottom: solid 4px var(--primary);
  background-color: var(--navigation);
  padding-left: 4px;
  line-height: 55px;
  position: sticky !important;
  top: 0;
  z-index: 10;
  transition: all 0.15s linear;
  opacity: 0.97;
}

@supports ((-webkit-backdrop-filter: saturate(90%) blur(20px)) or (backdrop-filter: saturate(90%) blur(20px))) {
  .tHeadRow {
    background: none;
    opacity: 0.97;
    -webkit-backdrop-filter: saturate(90%) blur(20px);
    backdrop-filter: saturate(90%) blur(20px);
  }
}

.tIcon {
  height: 30px;
  top: 10px;
  bottom: 10px;
  position: absolute;
  background-position: center center;
  width: 90px;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
}

.tIcon.AWS {
  background-image: url(/assets/images/gateways-aws.png);
  width: 65px;
}

.tIcon.Microsoft {
  background-image: url(/assets/svgs/Azure.svg);
  background-position-x: 15px;
}

.tIcon.Google {
  background-image: url(/assets/svgs/cloud-logo.svg);
}

.tIcon.AliCloud {
  background-image: url(/assets/svgs/AlibabaCloudSmall.svg);
}

.inviteStatus.tYes {
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  margin-top: 7px;
  width: 35px;
  height: 24px;
  display: inline-block;
}

.tRow:active {
  color: var(--primary);
  transition: 0.3s;
}

.inviteStatus.tYes:before {
  color: var(--green);
  content: '\e91e';
}

.inviteStatus:before {
  content: '\e904';
}

.inviteStatus {
  color: var(--red);
  font-family: 'icomoon' !important;
  speak: none;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  text-transform: none;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  margin-top: 7px;
  width: 35px;
  height: 24px;
  display: inline-block;
}

.tRow {
  border-left: 4px solid var(--navigation);
  background-color: var(--navigation);
  font-weight: normal;
  color: var(--tableText);
  line-height: 55px;
  position: relative;
  float: left;
  width: 100%;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.tRow:nth-child(even) {
  border-left: 4px solid var(--background);
  background-color: var(--background);
  background: var(--background);
}

.tRow:hover {
  border-left: 4px solid var(--primary);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.noData {
  width: 100%;
  height: 90%;
  top: 0px;
  position: absolute;
  background-image: url(/assets/svgs/NoData.svg);
  background-position: top 102px center;
  background-size: 180px;
  color: var(--icon);
  opacity: 0.25;
  font-size: 24px;
  text-align: center;
  padding-right: 30px;
  line-height: 553px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Open Sans';
}

.tCol .bubble {
  margin-left: 0px;
}

.tCol #NetworkTimeline .bubble {
  margin-left: 20px;
}

.tMenu.all {
  width: 160px;
}

.tMenu.open {
  opacity: 1;
  pointer-events: all;
}

.tMenu {
  position: absolute;
  width: 150px;
  background-color: var(--menu);
  border-radius: var(--inputBorderRadius);
  right: -20px;
  top: 30px;
  box-shadow: 0 0 0 1px var(--shadow), 0 8px 16px var(--shadow);
  z-index: 40;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0;
  height: 0px;
  pointer-events: none;
  transition: 0.5s;
  overflow: hidden;
  color: var(--white);
}

.tMenu .tActionRow {
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  color: var(--white);
  width: 100%;
  position: relative;
  float: left;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 11px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Open Sans';
}

.tMenu .tActionRow:hover {
  background-color: var(--primary);
  color: var(--white);
}

.tSelect {
  width: 25px;
  height: 25px;
  border: 2px var(--offWhite) solid;
  border-radius: 15px;
  cursor: pointer;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
  position: absolute;
  top: 13px;
  left: 17px;
  background-color: var(--navigation);
}

.tSelect:hover {
  border: 2px var(--primary) solid;
  cursor: pointer;
  background-color: var(--offWhite);
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.tSelect:active {
  transform: scale(0.9);
  transition: background-color 0.3s;
}

.tSelect.selected {
  background-color: var(--green);
  background-image: url(/assets/images/Icon_Check.png); //REPLACE
  background-size: 45%;
  background-position: center center;
  border: none;
}

.initials {
  width: 32px;
  height: 32px;
  border-radius: 18px;
  background-color: linear-gradient(125deg, var(--primary), var(--secondary));
  color: var(--white);
  position: absolute;
  left: 0px;
  top: 7px;
  text-align: center;
  text-transform: uppercase;
  line-height: 32px;
  margin-top: 3px;
  font-weight: 600;
  padding-left: 1px;
  margin-right: 10px;
  background-color: #08dc5a; /*Fallback Color for Old Browsers*/
}

.tColSpace {
  padding-left: 55px;
}

.tCol.ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 39px;
}

.tCol {
  position: relative;
  float: left;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Open Sans';
  text-overflow: ellipsis;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 7px;
}

.tRight {
  text-align: right;
  overflow: unset;
}

.clickCol {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dots {
  text-align: center;
  font-size: 42px;
  line-height: 30px;
  font-family: serif;
  letter-spacing: 0px;
  cursor: pointer;
  height: 40px;
  display: inline-block;
  width: 40px;
  color: var(--stroke);
  position: absolute;
  right: 20px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.dots:hover {
  color: var(--primary);
  transition: 0.5s;
}

.dots:active {
  color: var(--primaryColorHover);
  transition: 0.3s;
}

.modal .table {
  position: relative;
  width: 100%;
  display: grid;
  color: var(--text);
  font-size: 14px;
  line-height: 24px;
  grid-template-columns: auto;
  margin-bottom: 5px;
}

.modal .table div {
  padding: 10px;
}

.modal .table.three {
  grid-template-columns: 33% auto 33%;
}

.modal .table.two {
  grid-template-columns: 50% auto;
}

.modal .table .header {
  font-family: 'Open Sans';
  color: var(--tableText);
  font-weight: 800;
}

.borderBottom {
  border-bottom-color: var(--inputBorder);
  border-bottom-width: var(--inputBorderWidth);
  border-bottom-style: solid;
}

.borderRight {
  border-right-color: var(--inputBorder);
  border-right-width: var(--inputBorderWidth);
  border-right-style: solid;
}

.borderLeft {
  border-left-color: var(--inputBorder);
  border-left-width: var(--inputBorderWidth);
  border-left-style: solid;
}

.tableGrid {
  position: relative;
  width: 100%;
  display: grid;
  font-size: 12px;
  grid-template-columns: auto;
  grid-column-gap: 5px;
}

.tableGrid.header {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--tableText);
  border-bottom-style: solid;
  border-bottom-color: var(--primary);
  border-bottom-width: var(--inputBorderWidth);
}

.tableGrid.four {
  grid-template-columns: auto 25% 25% 25%;
}

.tableGrid .staticCell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  color: var(--tableText);
}

.gridLabel {
  color: var(--primary);
  line-height: 50px;
  font-weight: bold;
}

.t5 {
  width: 5%;
}

.t10 {
  width: 10%;
}

.t15 {
  width: 15%;
}

.t20 {
  width: 20%;
}

.t25 {
  width: 25%;
}

.t30 {
  width: 30%;
}

.t40 {
  width: 40%;
}

.t45 {
  width: 45%;
}

.t50 {
  width: 50%;
}

.t60 {
  width: 60%;
}

.t65 {
  width: 65%;
}

.t70 {
  width: 70%;
}

.t80 {
  width: 80%;
}

.t90 {
  width: 90%;
}

.filterButton {
  position: absolute;
  z-index: 20;
  width: 16px;
  height: 16px;
  top: 20px;
  right: 33px;
  background-image: url(/assets/svgs/Filter.svg);
  background-size: contain;
  background-position: center center;
  cursor: pointer;
}

.filterButton .options {
  position: absolute;
  right: 0px;
  z-index: 30;
  top: 20px;
  box-shadow: var(--shadows);
  border-radius: 8px;
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 0px;
  opacity: 0;
  overflow-x: hidden;
  overflow: hidden;
  background-color: var(--menu);
  transition: 0.75s;
}

.filterButton .options.open {
  width: 200px;
  height: unset;
  opacity: 1;
  overflow: auto;
  transition: 0.75s;
}

.filterButton .options .option {
  width: 100%;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  padding-left: 11px;
  padding-right: 10px;
  color: var(--white);
  cursor: pointer;
  overflow: hidden;
  font-weight: 600;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.filterButton .options .option:hover {
  background-color: var(--primary);
}
